import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import ContainerContact from "./Components/Container/ContainerContact";
import ContainerHome from "./Components/Container/ContainerHome";
import ContainerServices from "./Components/Container/ContainerServices";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Holiday from "./Components/HolidayGifs/Holiday";

import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";

function App() {
  const [loading, setLoading] = useState(false);
  const nav_select = useSelector((state) => state);

  function sleep(time) {
    const p = new Promise((res, rej) => {
      setTimeout(() => {
        res();
      }, time);
    });
    return p;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await sleep(2500);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      {nav_select === "home" && loading ? (
        <Holiday />
      ) : (
        <div>
          <div
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "#1e408a",
              padding: "20px 0",
              zIndex: "100",
            }}
          >
            <Header />
          </div>
          <div>
            <AnimatePresence exitBeforeEnter>
              <Routes>
                <Route path="/" element={<ContainerHome />} />
                <Route path="/services" element={<ContainerServices />} />
                <Route path="/contact" element={<ContainerContact />} />
              </Routes>
            </AnimatePresence>
          </div>
          <div className="footer_All">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
