import "./Footer.css";
import Transitions from "../Transition/Transitions";

function Footer() {
  return (
    <Transitions>
      <div className="footer_Block">
        <div>
          <p>
            <i className="fa fa-copyright"></i> Copyright 2024 - All Rights
            Reserved. CITMAC, Jaipur(Rajasthan) | Designed By: CITMAC
          </p>
        </div>
      </div>
    </Transitions>
  );
}
export default Footer;
