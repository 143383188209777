import { services } from "../Data/services";
import "./ContainerServices.css";
import Transitions from "../Transition/Transitions";

function ContainerServices() {
  //console.log(services[0]);
  return (
    <>
      <Transitions>
        <div className="service_block">
          <div>
            <h1 className="service_title">Our Services</h1>
          </div>
          <div>
            {services.map((item) => {
              const name = Object.keys(item)[0];
              return (
                <div className="single_service_block">
                  <h2 className="single_service_title">{name}</h2>
                  <ul className="single_service_list">
                    {item[name].map((item1) => {
                      const name1 = Object.keys(item1)[0];
                      return typeof item1 === "object" ? (
                        <li>
                          {name1}
                          <ul>
                            {item1[name1].map((item2) => {
                              return <li>{item2}</li>;
                            })}
                          </ul>
                        </li>
                      ) : (
                        <li>{item1}</li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </Transitions>
    </>
  );
}
export default ContainerServices;
