import "./Header.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { NavAction } from "../Action/NavAction/NavAction";
import Transitions from "../Transition/Transitions";

function Header() {
  const dispatch = useDispatch();
  const nav_select = useSelector((state) => state);
  const params = useLocation();
  if (params.pathname.substring(1) === "") dispatch(NavAction("home"));
  else dispatch(NavAction(params.pathname.substring(1)));
  //const [nav_border, setBorder] = useState({ borderTop: "2px solid transparent" });

  useEffect(() => {
    document.getElementsByName("nav_tags")[0].style.borderTop =
      "2px solid transparent";
    document.getElementsByName("nav_tags")[1].style.borderTop =
      "2px solid transparent";
    document.getElementsByName("nav_tags")[2].style.borderTop =
      "2px solid transparent";
    // document.getElementsByName("nav_tags")[3].style.borderTop =
    //   "2px solid transparent";
    document.getElementById(nav_select).style.borderTop = "2px solid white";
  }, [nav_select]);

  return (
    <>
      <Transitions>
        <div className="header_Block">
          <div className="title_Block">
            <Link
              to="/"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <h1 className="title_Name">citmac</h1>
              <div class="pyro">
                <div class="before"></div>
                <div class="after"></div>
              </div>
            </Link>
          </div>
          <div className="navigation_Block">
            <Link
              to="/"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div
                onClick={() => dispatch(NavAction("home"))}
                id="home"
                name="nav_tags"
              >
                Home
              </div>
            </Link>
            {/* <Link
              to="/about"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div
                onClick={() => dispatch(NavAction("about"))}
                id="about"
                name="nav_tags"
              >
                About
              </div>
            </Link> */}
            <Link
              to="/services"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div
                onClick={() => dispatch(NavAction("services"))}
                id="services"
                name="nav_tags"
              >
                Services
              </div>
            </Link>
            <Link
              to="/contact"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div
                onClick={() => dispatch(NavAction("contact"))}
                id="contact"
                name="nav_tags"
              >
                Contact
              </div>
            </Link>
          </div>
        </div>
      </Transitions>
    </>
  );
}
export default Header;
