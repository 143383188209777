import "./Holiday.css";
//import logo from "../Images/diwali.gif";

function Holiday() {
  return (
    <>
      {/* <div className="holiday_message">
        <img src={logo} alt="" />
      </div> */}

      <div className="logo_animation">
        <svg
          width="100%"
          height="700px"
          viewBox="20 55 175 175"
          style={{ overflow: "hidden", margin: "auto" }}
        >
          <g id="layer1">
            <path
              d="m 80.676851,140.43333 -0.696473,0.0374 -0.684023,0.11228 -0.67159,0.19961 -0.634283,0.26198 -0.6094,0.33684 -0.572103,0.39921 -0.52235,0.44912 -0.472597,0.52397 -0.410407,0.56139 -0.335797,0.6113 -0.28604,0.63625 -0.198987,0.67367 -0.12437,0.68615 -0.0498,0.68615 0.0249,0.69862 0.0995,0.69863 0.236297,-0.77348 0.32336,-0.73605 0.385537,-0.68615 0.472597,-0.6612 0.534777,-0.59882 0.58453,-0.53644 0.646723,-0.47407 0.696463,-0.39921 0.72134,-0.32437 0.758653,-0.2495 0.783523,-0.16218 z"
              id="path2091"
            />
            <path
              d="m 76.808979,145.74786 -1.865511,3.53055 0.323347,0.64873 0.397977,0.61129 0.4726,0.54892 0.534787,0.49902 0.584533,0.42417 0.62184,0.36178 0.671593,0.28694 0.69646,0.21208 0.708903,0.12476 0.721343,0.0374 v -2.04598 l -0.559663,-0.0873 -0.54722,-0.1497 -0.522353,-0.21209 -0.48504,-0.29941 -0.447723,-0.34931 -0.39798,-0.41169 -0.33579,-0.44912 -0.273613,-0.49901 -0.21143,-0.53645 -0.12437,-0.54892 -0.0622,-0.57387 0.0124,-0.56139 z"
              id="path2093"
            />
            <path
              d="m 92.976887,140.43333 h -8.892346 v 1.97112 h 1.144193 l 0.771083,0.52396 0.72134,0.59883 0.65915,0.64872 0.621837,0.69862 0.547217,0.761 0.472597,0.79843 0.41042,0.83586 0.33579,0.87328 v -5.7387 h 1.915276 z"
              id="path2095"
            />
            <path
              d="m 92.976887,150.98755 h -3.208719 l -0.0871,-0.78595 -0.14924,-0.77348 -0.22386,-0.761 -0.28605,-0.73605 -0.348227,-0.69863 -0.410407,-0.67367 -0.472597,-0.63625 -0.522347,-0.58635 0.136797,0.67368 0.0498,0.68615 -0.0124,0.67367 -0.11193,0.67367 -0.186547,0.6612 -0.26117,0.63625 -0.335803,0.59882 -0.39798,0.54892 -0.472603,0.49902 h -0.79595 l -0.795963,2.04597 h 8.892336 z"
              id="path2097"
            />
            <path
              d="m 101.48365,143.41496 h -2.362994 v 9.61856 h 2.362994 z"
              id="path2099"
            />
            <path
              d="M 105.58781,140.43333 H 95.01653 v 1.97112 h 10.57128 z"
              id="path2101"
            />
            <path
              d="m 110.56254,142.55415 2.62417,4.17927 h 2.30081 l -4.0544,-6.30009 h -2.68636 v 12.60019 h 1.81578 z"
              id="path2103"
            />
            <path
              d="m 119.82798,140.43333 h -2.74854 l -1.96502,3.25609 h 2.18888 l 0.7089,-1.13527 v 10.47937 h 1.81578 z"
              id="path2105"
            />
            <path
              d="m 130.8097,140.43333 h -2.07695 l -5.22347,12.60019 h 2.07695 z"
              id="path2107"
            />
            <path
              d="m 133.42144,146.73342 h -2.07695 l 2.61173,6.3001 h 2.07695 z"
              id="path2109"
            />
            <path
              d="m 139.22944,145.74786 -1.86552,3.53055 0.32335,0.64873 0.39798,0.61129 0.4726,0.54892 0.53479,0.49902 0.58453,0.42417 0.62184,0.36178 0.67159,0.28694 0.69646,0.21208 0.7089,0.12476 0.72134,0.0374 v -2.04598 l -0.55966,-0.0873 -0.54722,-0.1497 -0.52235,-0.21209 -0.48504,-0.29941 -0.44772,-0.34931 -0.39798,-0.41169 -0.3358,-0.44912 -0.2736,-0.49901 -0.21143,-0.53645 -0.12437,-0.54892 -0.0622,-0.57387 0.0124,-0.56139 z"
              id="path2111"
            />
            <path
              d="m 143.0973,142.40445 v -1.97112 l -0.69647,0.0374 -0.68402,0.11228 -0.67159,0.19961 -0.63428,0.26198 -0.60941,0.33684 -0.57209,0.39921 -0.52235,0.44912 -0.4726,0.52397 -0.41041,0.56139 -0.33579,0.6113 -0.28605,0.63625 -0.19899,0.67367 -0.12437,0.68615 -0.0498,0.68615 0.0249,0.69862 0.0995,0.69863 0.2363,-0.77348 0.32336,-0.73605 0.38554,-0.68615 0.4726,-0.6612 0.53478,-0.59882 0.58453,-0.53644 0.64672,-0.47407 0.69646,-0.39921 0.72134,-0.32437 0.75864,-0.2495 z"
              id="path2113"
            />
          </g>
        </svg>
      </div>
    </>
  );
}
export default Holiday;
