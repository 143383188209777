export const services = [
  {
    "Civil": ["Chartered Engineer Certificate"],
  },
  {
    "Mechanical": [
      "Chartered Engineer Certificate",
      "Registered Valuer Certificate",
    ],
  },
  {
    "Factory": [
      "Competent Person",
      "Factory Plan/ Drawing",
      { "Factory License": ["Registration", "Renewal", "Amendment"] },
      "Annual Return",
    ],
  },
  {
    "Business Registration Number": ["Business Registration No. (BRN)"],
  },
  {
    "Environment": ["Environmental Clearance (EC)"],
  },
  {
    "Pollution": [
      {
        "Consent to Establish (CTE) & Consent to Operate (CTO), Air and, or Water":
          ["New", "Renewal", "Expansion"],
      },
      "Authorisation under Hazardous and Other Wastes (Management and Transboundary Movement), Rules 2016",
    ],
  },
  {
    "Borewell Permission": ["NOC to abstract Ground Water"],
  },
  {
    "Fire Act": [{ "Fire NOC": ["New", "Renewal"] }],
  },
  {
    "Labor Act": [
      "Standing Order",
      {
        "Registration & Renewal": [
          "Shop Act",
          "Contract Labor Act"
        ],
      },
    ],
  },
  {"FSSAI":["Registration & License"]},
  {"UDYAM":["Registration"]}
];
