import "./ContainerContact.css";
import Transitions from "../Transition/Transitions";

function ContainerContact() {
  return (
    <Transitions>
      <div>
        <div>
          <div className="contact_background"></div>
        </div>
        <div className="contact_block">
          <div>
            <h3 className="contact_info">
              For more information on our services, please reach us at:
            </h3>
          </div>
          <div>
            <h4 className="contact_mail">
              Email:{"  "}
              <span style={{ color: "#edc1a1" }}>info@citmac.co.in</span>
            </h4>
          </div>
          <div>
            <h4 className="contact_add">
              Office:{"  "}
              <span style={{ color: "#a2eb83" }}>
                Jaipur (Rajasthan) - 302020
              </span>
            </h4>
          </div>
        </div>
      </div>
    </Transitions>
  );
}
export default ContainerContact;
