import "./ContainerHome.css";
import civil from "../Images/civil.jpg";
import mech from "../Images/mechanical.jpg";
import other from "../Images/other.jpg";
//import it from "../Images/it.jpg";
import Transitions from "../Transition/Transitions";

function ContainerHome() {
  return (
    <>
      <Transitions>
        <div>
          <div>
            <div className="home_background">
              <div className="intro">
                <p style={{ margin: "0" }}>
                  Gateway to solutions for all your problems
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="service_Title">
              <h2>We offer consulting in</h2>
            </div>
            <div className="image_Block">
              <div className="image_Block_Div">
                <div className="image_Div">
                  <img src={civil} alt="" />
                </div>
                <div className="image_Title">CIVIL</div>
              </div>
              <div className="image_Block_Div">
                <div className="image_Div">
                  <img src={mech} alt="" />
                </div>
                <div className="image_Title">MECHANICAL</div>
              </div>
              {/* <div className="image_Block_Div">
                <div className="image_Div">
                  <img src={it} alt="" />
                </div>
                <div className="image_Title">IT</div>
              </div> */}
              <div className="image_Block_Div">
                <div className="image_Div">
                  <img src={other} alt="" />
                </div>
                <div className="image_Title">STATUTORY</div>
              </div>
            </div>
          </div>
        </div>
      </Transitions>
    </>
  );
}
export default ContainerHome;
