import { NavActionType } from "../Action/ActionType/ActionType";

export const NavReducer = (state = "home", action) => {
  switch (action.type) {
    case NavActionType:
      return action.payload;
    default:
      return state;
  }
};
